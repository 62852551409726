<template>
    <div
        class="flex h-full w-80 flex-col overflow-auto border-r bg-gray-25 px-6 py-4"
        ref="el"
    >
        <p class="py-1.5 text-xl font-medium text-gray-900">
            {{ $t('assistant.title') }}
        </p>

        <base-button
            icon="line-icons:general:plus"
            class="my-2.5 w-full"
            @click="onNewChat"
        >
            {{ $t('assistant.newConversation') }}
        </base-button>

        <hr class="mb-5" />

        <div
            class="flex flex-col gap-4"
            v-for="filter in filteredConversations"
            :key="filter"
        >
            <p class="text-xs text-gray-500">
                {{ filter.title }}
            </p>

            <router-link
                v-for="conversation in filter.data"
                :key="conversation.id"
                :to="{
                    name: 'assistant.conversation',
                    params: { id: conversation.id },
                }"
                class="cursor-pointer rounded-md bg-gray-200 px-3 py-2 text-sm text-gray-700 hover:bg-gray-300"
                active-class="bg-gray-300"
            >
                {{ truncateString(conversation.title, 28) }}
            </router-link>
        </div>

        <div class="flex items-center justify-center p-4" v-if="isFetching">
            <base-loading />
        </div>
    </div>
</template>

<script setup>
import { useInfiniteQuery } from '@tanstack/vue-query'
import { uniqBy } from 'lodash-es'
import dayjs from 'dayjs'
import { useInfiniteScroll } from '@vueuse/core'
import { truncateString } from '@tenant/utils/helper'

const { t } = useI18n()
const router = useRouter()
const { user } = useAuth()

useHead({
    title: t('assistant.title'),
})

const { execute } = useApi('api/assistant/conversations', 'GET')

const {
    data: conversations,
    fetchNextPage,
    hasNextPage,
    isFetching,
} = useInfiniteQuery({
    queryKey: ['assistant-conversations'],
    queryFn: ({ pageParam = 1 }) => {
        return execute({
            queries: {
                limit: 20,
                cursor: pageParam,
                'sort[col]': 'updated_at',
                'sort[by]': 'desc',
                'filter[user_id]': user.value?.id,
            },
        })
    },
    getNextPageParam: ({ next_cursor }) => next_cursor,
    select: (res) =>
        uniqBy(
            res?.pages?.flatMap((x) => x?.data),
            (x) => x?.id
        ),
    staleTime: Infinity,
})

// NEW CHAT
const onNewChat = async () => {
    await router.push({
        name: 'assistant.chat',
    })
}
// END: NEW CHAT

const filteredConversations = computed(() => {
    const filtered = {
        thisMonth: {
            title: t('assistant.thisMonth'),
            data: [],
        },
    }

    conversations.value?.forEach((conversation) => {
        const conversationMonth = dayjs(conversation.updated_at).month()
        const currentMonth = dayjs().month()

        if (conversationMonth === currentMonth) {
            filtered.thisMonth.data.push(conversation)
        }
    })

    return Object.keys(filtered).reduce((acc, key) => {
        acc.push(filtered[key])

        return acc
    }, [])
})

const el = ref(null)
useInfiniteScroll(
    el,
    () => {
        if (hasNextPage.value) {
            fetchNextPage()
        }
    },
    { distance: 10 }
)
</script>
