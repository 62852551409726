<template>
    <div class="flex flex-1 grow flex-col border-r">
        <div class="flex h-full flex-col">
            <div
                class="flex items-center justify-between border-b border-gray-200 px-6 py-4"
            >
                <h5
                    class="flex items-center gap-1.5 py-1.5 text-xl font-medium text-gray-900"
                >
                    {{ $t('assistant.newConversation') }}
                    <base-badge variant="warning" :label="'Beta'" size="sm" />
                </h5>
            </div>

            <div
                ref="chatWindow"
                class="flex h-full w-full flex-col gap-6 overflow-auto p-4"
            >
                <!-- Conversation -->
                <assistant-sample-questions
                    v-if="!conversation"
                    @ask-question="askQuestion"
                />

                <div class="flex flex-col gap-6" :key="updateKey">
                    <div class="flex gap-6" v-for="row in data" :key="row.key">
                        <div class="relative">
                            <base-avatar
                                v-if="row.role === 'user'"
                                class="h-[48px] w-[48px]"
                                size="md"
                                :user="user"
                            />

                            <img
                                v-if="row.role === 'assistant'"
                                class="h-[48px] w-[48px] rounded-full bg-gray-100 object-cover p-2"
                                :src="
                                    $filters.asset(
                                        'static/images/assistant.svg'
                                    )
                                "
                            />
                        </div>

                        <div class="flex-1">
                            <p class="mb-2.5 text-sm text-gray-600">
                                {{
                                    row.role === 'user'
                                        ? user.full_name
                                        : $t('assistant.assistantText')
                                }}
                            </p>

                            <div
                                class="rounded-sm rounded-tl-none bg-gray-50 p-6 text-sm text-gray-700"
                                :class="{
                                    'bg-primary-50': row.role === 'user',
                                }"
                            >
                                <div
                                    v-if="row.content"
                                    class="chat-response flex flex-col"
                                    v-html="marked.parse(row.content)"
                                ></div>

                                <div
                                    v-else-if="row?.loading"
                                    class="flex gap-1 pt-1"
                                >
                                    <div class="flex gap-1 pt-1">
                                        <div
                                            class="h-1 w-1 animate-[typing_2s_linear_infinite] rounded-full"
                                        />
                                        <div
                                            class="h-1 w-1 animate-[typing_2s_linear_infinite_250ms] rounded-full"
                                        />
                                        <div
                                            class="h-1 w-1 animate-[typing_2s_linear_infinite_500ms] rounded-full"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <base-loading
                v-if="isFetching"
                class="flex flex-1 items-center justify-center"
            />

            <!-- Footer form -->
            <div class="px-6 py-4">
                <form class="flex gap-3" @submit.prevent="sendMessage">
                    <form-text-input
                        class="flex-1"
                        v-model="message"
                        :error-message="errorMessage"
                        hide-message
                        :placeholder="$t('assistant.chatInputPlaceholder')"
                        autofocus
                        :full-width="false"
                    />
                    <base-button
                        type="submit"
                        class="p-3"
                        :has-padding="false"
                        :disabled="loading || typing"
                    >
                        {{ $t('assistant.askNowBtn') }}
                    </base-button>
                </form>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useAssistantChat } from '@tenant/composables/use-assistant'
import { uniqueId } from 'lodash'
import { useInfiniteScroll } from '@vueuse/core'
import { useQueryClient } from '@tanstack/vue-query'
import { ECHO_EVENTS } from '@tenant/utils/constants'
import { usePrivateChannel } from '@tenant/composables'
import { marked } from 'marked'

const { user } = useAuth()
const { handleSubmit } = useForm()
const route = useRoute()
const router = useRouter()
const { centralUser } = useAuth()
const emitter = useEmitter()

const { echo } = usePrivateChannel(`assistant.user.${centralUser.value.id}`)
onUnmounted(() => {
    echo.value.stopListening(ECHO_EVENTS.ASSISTANT_MESSAGE)
})

const { value: message, errorMessage } = useField('message')
const { value: conversation } = useField('conversation_id', undefined, {
    initialValue: route.params.id,
})
const { execute: executeSendMessage, loading } = useApi(
    'api/assistant/chat/{id}',
    'POST'
)
const typing = ref(false)

const sendMessage = handleSubmit(async () => {
    if (typing.value) return

    // If there's no conversation, create a new one
    // and redirect to the new conversation with the message from user
    if (!conversation.value) {
        await executeCreateConversation()
        conversation.value = newConversation.value.id
        localStorage.setItem(conversation.value, message.value)
        await router.replace({
            name: 'assistant.conversation',
            params: { id: newConversation.value.id },
            props: { default: true, sidebar: false },
        })
        return
    }

    const messageContent = message.value
    message.value = null

    await addMessageAndAssistantResponse({
        message: messageContent,
        conversation_id: conversation.value,
    })
})

// CONVERSATIONS
const {
    data,
    chatWindow,
    scrollToBottom,
    loadMore,
    isFetching,
    addMessages,
    appendMessage,
} = useAssistantChat(conversation)

useInfiniteScroll(
    chatWindow,
    () => {
        loadMore()
    },
    { distance: 10 }
)
// END: CONVERSATIONS

const { execute: executeCreateConversation, result: newConversation } = useApi(
    'api/assistant/conversations',
    'POST'
)

const queryClient = useQueryClient()
onMounted(async () => {
    // Get the message from the previous screen, append it to the chat box,
    // and remove it from local storage
    const message = localStorage.getItem(conversation.value)
    localStorage.removeItem(conversation.value)

    await queryClient.refetchQueries(['assistant-conversations'])

    if (message) {
        // If there's a message in local storage, append it straight to the chat box
        // Cancel the previous query to avoid duplication
        await queryClient.cancelQueries(['assistant-chat', conversation.value])

        await addMessageAndAssistantResponse({
            message,
            conversation_id: conversation.value,
        })
    }
})

/**
 * Add message from user and response from assistant
 * @param values
 * @returns {Promise<void>}
 */
const addMessageAndAssistantResponse = async (values) => {
    echo.value.listen(ECHO_EVENTS.ASSISTANT_MESSAGE, (...args) => {
        emitter.emit(`echo-${ECHO_EVENTS.ASSISTANT_MESSAGE}`, ...args)
    })

    try {
        // Add message/question from user
        addMessages(
            {
                data: [
                    {
                        id: uniqueId(),
                        role: 'user',
                        content: values.message,
                    },
                ],
            },
            conversation.value
        )

        messageId.value = uniqueId()
        addMessages(
            {
                data: [
                    {
                        id: messageId.value,
                        role: 'assistant',
                        content: '',
                        loading: true,
                    },
                ],
            },
            conversation.value
        )

        scrollToBottom()

        await executeSendMessage({
            params: {
                id: conversation.value,
            },
            data: {
                message: values.message,
            },
        })

        // Save question and get response from assistant
        await queryClient.refetchQueries(['assistant-conversations'])
        scrollToBottom()
    } catch {
        echo.value.stopListening(ECHO_EVENTS.ASSISTANT_MESSAGE)
    }
}

const askQuestion = (question) => {
    message.value = question
    sendMessage()
}

const messageId = ref('')
const updateKey = ref(0)
useListenEmitEcho(
    ECHO_EVENTS.ASSISTANT_MESSAGE,
    ({ message, conversationId }) => {
        // If the message does not belong to the current conversation, ignore it
        if (conversationId !== conversation.value) return

        typing.value = true

        if (!messageId.value) {
            messageId.value = uniqueId()
            addMessages(
                {
                    data: [
                        {
                            id: messageId.value,
                            role: 'assistant',
                            content: message.delta.content ?? '',
                            loading: false,
                        },
                    ],
                },
                conversation.value
            )
        }
        appendMessage(
            message.delta.content ?? '',
            conversation.value,
            messageId.value
        )
        updateKey.value++

        if (message.finish_reason === 'stop') {
            typing.value = false
            messageId.value = ''
            // Stop listening to the event to avoid duplication
            echo.value.stopListening(ECHO_EVENTS.ASSISTANT_MESSAGE)
        }

        scrollToBottom()
    }
)
</script>

<style lang="scss">
.chat-response {
    ol,
    ul {
        list-style: revert;
        margin: revert;
        padding: revert;
    }
}
</style>
