export default {
    assistant: {
        title: 'Assistant',
        newConversation: 'New Conversation',
        thisMonth: 'This Month',
        today: 'Today',
        guidelineTitle: '🧾 Welcome to Tax Bot! 🧾',
        guidelineDescription:
            "I'm here to help answer your questions related to corporate tax, tax legislation, and the complexities of tax codes across various jurisdictions. Feel free to ask me anything related to corporate taxes. Try out a sample request below or send me any request in the message box!",
        askNowBtn: 'Ask Now',
        question1: 'What’s the corporate tax?',
        question1Content: 'What is the corporate tax?',
        question2: 'Income Tax Brackets',
        question2Content: 'What are the Income Tax Brackets?',
        question3: '50% exemption',
        question3Content: 'Explain me the 50% income exemption for employees',
        assistantText: 'Assistant',
        chatInputPlaceholder:
            'Ask me anything about corporate taxes and I’ll do my best to help!',
    },
}
